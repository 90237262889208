.scroll-anim {
  opacity: 0;
  position: relative;
}
.fade-in {
  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.slide-in-left {
  animation: slideInLeftAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
@keyframes slideInLeftAnimation {
  0% {
    opacity: 0;
    left: -50%;
  }
  75% {
    left: 0;
  }
  100% {
    opacity: 1;
  }
}
.slide-in-right {
  animation: slideInRightAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
@keyframes slideInRightAnimation {
  0% {
    opacity: 0;
    right: -50%;
  }
  75% {
    right: 0;
  }
  100% {
    opacity: 1;
  }
}
