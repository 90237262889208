/* --------------------------------------------------------------
    Import CCS Dependencies
-------------------------------------------------------------- */
@import 'colors';
@import 'icons';
@import 'fonts';
@import 'default';
@import 'nav';
@import 'buttons';
@import 'animations';
/* --------------------------------------------------------------
    Start Project CSS
-------------------------------------------------------------- */
html {
  position: relative;
  height: 100%;
  background-color: #fff;
  // overflow-y: scroll;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
}
body {
  height: 100%;
  font-family: var(--font-family);
  text-rendering: optimizeLegibility;
  color: #555555;
}
.wrap {
  padding: 30px 0;
}
.container-fluid.but-fixed {
  max-width: 1356px;
}
@media (min-width: 1400px) {
  .container-fluid.but-fixed {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }
}
.footer {
  padding-top: 20px;
  padding-bottom: 15px;
  width: 100%;
  background-color: #ffffff;
  color: #595959;
  border-top: 1px solid #e4e4e4;
  font-size: 0.9rem;
  img {
    display: inline-block;
  }
  a {
    color: darken($theme-primary, 7.5%);
  }
}
.box-shadow {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}
.text-shadow {
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
}
.carousel-indicators {
  margin-bottom: -2rem;
}
.carousel-control-prev {
  left: -9.5%;
}
.carousel-control-next {
  right: -9.5%;
}

/* --------- asc styles --------- */
.skip-link {
  color: #fff !important;
  background: $theme-primary !important;
  padding: 10px 20px !important;
  border-bottom: none !important;
  text-decoration: underline !important;
  z-index: 9999 !important;
}
#header,
#sideNav {
  background: rgba(255, 255, 255, 0.8);
  float: none;
  a.logo {
    float: left;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 0;
    width: 75%;
    max-width: 320px;
    img {
      margin-right: 10px;
      vertical-align: middle;
      width: auto;
      height: auto;
      max-width: 270px;
      width: 270px;
    }
    span {
      display: inline;
      font-size: 23px;
      font-weight: 600;
      color: $header-color;
      line-height: 0.9em;
      vertical-align: middle;
    }
  }
  #support {
    display: none;
    float: right;
    margin: 0 0 0 0;
    width: auto;
    a.help-center {
      margin: 0 10px 0 0;
      font-weight: normal;
      color: $theme-primary;
      border: 0 !important;
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e830';
        color: inherit;
        font-family: 'icons';
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
      &:hover {
        color: lighten($theme-primary, 7.5%);
      }
    }
    a.login {
      float: right;
      margin: 0 0 0 0;
      padding: 0 12px 2px 12px;
      font-weight: normal;
      color: #fff;
      background: $theme-primary;
      text-decoration: none;
      border-radius: 0 0 4px 4px;
      border: 0;
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e828';
        color: inherit;
        font-family: 'icons';
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
      &:hover {
        background: lighten($theme-primary, 7.5%);
      }
    }
    span.phone {
      display: none;
      padding-right: 8px;
      border-right: 1px dotted #333;
      color: $theme-primary;
    }
  }
  #search {
    float: right;
    clear: right;
    margin: 12px 0;
    input[type='search'] {
      width: 130px;
      transition: all 0.5s cubic-bezier(0, 0.7, 0, 1);
      &:focus {
        width: 250px;
        background-color: #fff;
      }
      &::placeholder {
        color: #999 !important;
      }
    }
  }
}
#banner-wrap {
  z-index: 1;
  .overlay,
  .full-overlay {
    background: rgba(18, 95, 118, 0.85);
    &.green {
      background: rgba(46, 92, 44, 0.85);
    }
  }
  #text-two ul li a {
    text-decoration: underline;
    border: 0;
    color: #fff;
    &:hover {
      color: darken(#fff, 10%);
    }
  }
  .text-wrap {
    color: #fff;
  }
  &.home {
    background: $theme-primary url(../images/banners/home-banner.jpg) no-repeat 50% 50% / cover;
    #banner {
      position: relative;
      h1 {
        line-height: 30px;
      }
      .card {
        background: rgba(255, 255, 255, 0.85);
      }
    }
  }
  &.cultivation {
    background: $theme-primary url(../images/banners/cultivate-banner.jpg) no-repeat 50% 50% / cover;
  }
  &.delivery {
    background: $theme-primary url(../images/banners/delivery-banner.jpg) no-repeat 50% 50% / cover;
  }
  &.retail {
    background: $theme-primary url(../images/banners/retail-banner.jpg) no-repeat 50% 50% / cover;
  }
  &.rvt {
    background: $theme-primary url(../images/banners/rvt-banner.jpg) no-repeat 50% 50% / cover;
  }
  &.bulk {
    background: $theme-primary url(../images/banners/rvt-banner.jpg) no-repeat 50% 100% / cover;
  }
}

#sideNav {
  span.big,
  span.small {
    display: inline;
    font-size: 1.5em;
    font-weight: 500;
    color: #333;
    line-height: 1em;
    vertical-align: middle;
  }
  span.big {
    font-weight: 600;
    font-family: var(--font-family);
    line-height: 1.2;
  }
  span.small {
    display: none;
    margin-top: 0;
    font-size: 16px;
    letter-spacing: 0.03rem;
    font-family: var(--font-family);
    font-weight: 600;
  }
  .search-in-nav {
    box-shadow: none;
  }
}
.bg-light {
  background: #f6f9fb !important;
}
.bg-primary {
  background: $theme-primary !important;
}
.bg-gradient-blue {
  background: $theme-primary;
  background: linear-gradient(30deg, #135064, #135064 30%, $theme-primary);
}
.bg-image {
  background: $theme-primary url(../images/banners/home-secondary-banner.jpg) no-repeat 50% 50% / cover;
}
.bg-alternate {
  background: darken(#418545e6, 5%);
  background: linear-gradient(180deg, darken(#418545e6, 5%) 0, darken(#418545e6, 5%) 50%, darken(#bd5a2ee6, 5%) 50.1%);
}

#home-carousel {
  .card-header {
    background: $theme-primary;
    &#retail {
      background: $theme-primary url(../images/retail-mini.jpg) no-repeat 0 0 / cover;
    }
    &#delivery {
      background: $theme-primary url(../images/delivery-mini.jpg) no-repeat 0 20% / cover;
    }
    &#cultivation {
      background: $theme-primary url(../images/cultivation-mini.jpg) no-repeat 0 0 / cover;
    }
    &#manufacturing {
      background: $theme-primary url(../images/manufacturing-mini.jpg) no-repeat 0 0 / cover;
    }
    .card-overlay {
      background: rgba(18, 95, 118, 0.75);
      border-radius: 4px 4px 0 0;
      padding: 20px 0;
    }
  }
}
input.addsearch {
  background: #f2f2f2
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24'%3E%3Cg fill='none' stroke='gray' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2'%3E%3Cpath d='M10 3a7 7 0 107 7 7 7 0 00-7-7zM21 21l-6-6' vector-effect='non-scaling-stroke'%3E%3C/path%3E%3C/g%3E%3C/svg%3E")
    no-repeat 9px center;
  padding-left: 35px;
}
.dynamic-form {
  min-height: 678px;
}
.btn-width {
  width: 220px;
}
/* ----------------------------------------------------------------------------
    Page Media Queries
----------------------------------------------------------------------------- */

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .container-fluid.but-fixed {
    padding-left: 30px;
    padding-right: 30px;
  }
  #header a.logo,
  #sideNav a.logo {
    span {
      font-size: 28px;
    }
    img {
      display: block;
      float: left;
      margin-right: 15px;
    }
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  #header a.logo {
    width: 50%;
  }
  #header-wrap {
    #header #support {
      display: block;
      span.phone {
        display: inline-block;
      }
    }
  }
  #banner-wrap {
    .overlay {
      background: linear-gradient(68deg, rgba(18, 95, 118, 0.85) 0%, rgba(18, 95, 118, 0.8) 56%, rgba(0, 0, 0, 0%) 56.1%);
      background: -moz-linear-gradient(22deg, rgba(18, 95, 118, 0.85) 0, rgba(18, 95, 118, 0.8) 50%, rgba(0, 0, 0, 0) 56%);
      &.green {
        background: linear-gradient(68deg, rgba(46, 92, 44, 0.85) 0%, rgba(46, 92, 44, 0.8) 56%, rgba(0, 0, 0, 0%) 56.1%);
        background: -moz-linear-gradient(22deg, rgba(46, 92, 44, 0.85) 0, rgba(46, 92, 44, 0.8) 50%, rgba(0, 0, 0, 0) 56%);
      }
      .indent {
        padding-left: 15px;
      }
    }
    .text-wrap {
      max-width: 500px;
    }
    &.home {
      #banner {
        .card {
          max-width: 530px;
        }
      }
    }
  }
  .bg-alternate {
    background: darken(#418545e6, 5%);
    background: linear-gradient(68.1deg, darken(#418545e6, 5%) 0, darken(#418545e6, 5%) 50%, darken(#bd5a2ee6, 5%) 50.1%);
  }
  .green-side {
    margin-right: 18%;
  }
  .orange-side {
    margin-left: 18%;
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  #banner-wrap {
    .overlay {
      background: linear-gradient(68deg, rgba(18, 95, 118, 0.85) 0%, rgba(18, 95, 118, 0.8) 50%, rgba(0, 0, 0, 0%) 50.1%);
      background: -moz-linear-gradient(22deg, rgba(18, 95, 118, 0.85) 0, rgba(18, 95, 118, 0.8) 50%, rgba(0, 0, 0, 0) 50%);
      &.green {
        background: linear-gradient(68deg, rgba(46, 92, 44, 0.85) 0%, rgba(46, 92, 44, 0.8) 50%, rgba(0, 0, 0, 0%) 50.1%);
        background: -moz-linear-gradient(22deg, rgba(46, 92, 44, 0.85) 0, rgba(46, 92, 44, 0.8) 50%, rgba(0, 0, 0, 0) 50%);
      }
    }
    &.home {
      background: $theme-primary url(../images/banners/home-banner.jpg) no-repeat 0 0 / cover;
      #banner {
        .card {
          background: #fff;
        }
      }
    }
  }
}
